"use client";

/**
 * Third-party libraries.
 */
import { Call, Device } from "@twilio/voice-sdk";
import { notification } from "antd";
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";

/**
 * Project components.
 */
import { useAuthenticationContext } from "@/components/client/authentication";
import { useApplicationContext } from "@/components/client/context";
import { UserAvailabilityStatus } from "@/components/client/graphql";
import { TwilioCallEvent, useTwilioDevice } from "@/components/client/twilio";
import {
  CallCustomParameters,
  CallParameters,
} from "@/components/common/twilio/types";

export type CallWithCustomProperties = Call & {
  /**
   * Date the call was initiated.
   */
  date?: Date;
  /**
   * Indicates whether the call is muted.
   */
  muted?: boolean;
};

/**
 * Twilio context.
 *
 * Provides Twilio device, call states and functions:
 * - Twilio call actions:
 *    - Get call.
 *    - Check if call is muted.
 *    - Mute call.
 * - Twilio call states:
 *    - Connected calls.
 * - Twilio device actions:
 *    - Register device.
 *    - Unregister device.
 * - Twilio device states
 *    - Device instance.
 *    - Device error.
 *    - Device is initializing.
 *    - Device is registered.
 *    - Device is registering.
 */
export type TwilioContext = {
  // ===========================================================================
  // Twilio device states.
  // ===========================================================================
  /**
   * Twilio device.
   */
  device: Device | null;
  /**
   * Twilio device error.
   */
  deviceError: unknown;
  /**
   * Twilio device is loading.
   */
  deviceInitializing: boolean;
  /**
   * Twilio device is registered.
   */
  deviceRegistered: boolean;
  /**
   * Twilio device is registering.
   */
  deviceRegistering: boolean;
  /**
   * Register the Device instance with Twilio, allowing it to receive incoming calls.
   *
   * This will open a signaling WebSocket, so the browser tab may show the 'recording' icon.
   *
   * It's not necessary to call device.register() in order to make outgoing calls.
   */
  registerDevice: () => Promise<void>;
  /**
   * Unregister the Device instance with Twilio. This will prevent the Device
   * instance from receiving incoming calls.
   */
  unregisterDevice: () => Promise<void>;
  // ===========================================================================
  // Twilio call functions and states.
  // ===========================================================================
  /**
   * Active twilio call.
   */
  calls: Call[];
  /**
   * Checks if the call is muted.
   */
  isMuted: (args: {
    /**
     * Twilio call SID of the call to be muted/unmuted.
     */
    callSid: string;
  }) => boolean;
  /**
   * Get the call with the specified call SID.
   */
  getCall: (args: { callSid: string }) => CallWithCustomProperties | null;
  /**
   * Mute/Unmute the selected call.
   */
  toggleMute: (args: {
    /**
     * Twilio call SID of the call to be muted/unmuted.
     */
    callSid: string;
  }) => void;
};

/**
 * Twilio related context.
 */
const TwilioContext = React.createContext<TwilioContext>({
  // ===========================================================================
  // Twilio device states.
  // ===========================================================================
  device: null,
  deviceError: null,
  deviceInitializing: true,
  deviceRegistered: false,
  deviceRegistering: false,
  registerDevice: () => Promise.resolve(),
  unregisterDevice: () => {
    return Promise.resolve();
  },
  // ===========================================================================
  // Twilio call functions and states.
  // ===========================================================================
  calls: [],
  isMuted: () => false,
  getCall: () => null,
  toggleMute: () => {},
});

/**
 * Use Twilio context.
 */
export const useTwilioContext = () => {
  return React.useContext(TwilioContext);
};

/**
 * Twilio context provider.
 */
export const TwilioContextProvider = ({ children }: PropsWithChildren) => {
  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  /**
   * The active Twilio call.
   * The purpose of this variable is to store one or more active calls of an
   * agent. This is so we can easily access them if we need to interact with them.
   *
   * @see https://www.twilio.com/docs/voice/sdks/javascript/twiliocall
   */
  const [calls, setCalls] = useState<CallWithCustomProperties[]>([]);

  /**
   * Stores the Twilio call SID of the muted calls.
   */
  const [mutedCalls, setMutedCalls] = useState<string[]>([]);

  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================

  /**
   * User availability status.
   */
  const { setUserAvailabilityStatus } = useApplicationContext();

  const { user } = useAuthenticationContext();

  const {
    destroyDevice,
    device: device,
    error: deviceError,
    initialize: initializeDevice,
    initializing: deviceInitializing,
    registered: deviceRegistered,
    registering: deviceRegistering,
    registerDevice,
    unregisterDevice,
  } = useTwilioDevice({
    callback: {
      destroyed: () => {
        console.log("Twilio device destroyed.");

        setUserAvailabilityStatus({
          status: UserAvailabilityStatus.Offline,
        });
      },
      error: (error) => {
        console.error("Twilio device error:", error);
      },
      incoming: ({ call }) => {
        console.log("Incoming call:", call);

        /**
         * Add the call to the list of connected calls.
         */
        addCall({ call });
      },
      registered: () => {
        console.log("Twilio device registered.");
      },
      registering: () => {
        console.log("Twilio device registering.");
      },
      token_will_expire: () => {
        console.log("Twilio device token will expire.");
      },
      unregistered: () => {
        console.log("Twilio device unregistered.");
      },
    },
  });

  // ===========================================================================
  // ===========================================================================
  // Functions
  // ===========================================================================
  // ===========================================================================

  /**
   * Remove the call from the list of connected calls and the muted calls as
   * applicable.
   */
  function removeCall({
    call,
  }: {
    /**
     * Twilio call instance.
     */
    call: Call;
  }) {
    // Remove the call from the list of connected calls.
    setCalls(
      (previousCalls) =>
        previousCalls.filter(
          (previousCall) =>
            previousCall.parameters.CallSid !== call.parameters.CallSid,
        ) ?? [],
    );

    // Remvoe the call from the muted calls if it exists.
    setMutedCalls((previousMutedCalls) =>
      previousMutedCalls.filter(
        (mutedCallSid) => mutedCallSid !== call.parameters.CallSid,
      ),
    );
  }

  /**
   * Attach event listeners to the call.
   */
  const setCallEventListeners = useCallback(
    ({
      call,
    }: {
      /**
       * Twilio call instance.
       */
      call: Call;
    }) => {
      const onCallAccept = (call: Call) => {
        console.log("Call accepted. Call SID: ", call.parameters.CallSid);
      };

      const onCallCancel = () => {
        console.log("Call canceled.");
        removeCall({ call });
      };

      const onCallDisconnect = () => {
        console.log("Call disconnected.");
        removeCall({ call });
      };

      const onCallMute = (
        /**
         * Indicates whether the call is muted.
         */
        muted: boolean,
        /**
         * Twilio call instance.
         */
        call: Call,
      ) => {
        console.log("Call muted:", muted);

        /**
         * Twilio call SID.
         *
         * Use the custom parameters if it exists because the parameters.CallSID
         * changes when a call is forwarded from the backend to the client device,
         * otherwise use the call parameters.
         */
        const callSid =
          (call.customParameters.get(
            "callSid",
          ) as CallCustomParameters["callSid"]) ||
          (call.parameters as CallParameters).CallSid;

        // Add the call SID to the muted calls list.
        if (muted) {
          setMutedCalls((previousMutedCalls) => [
            ...previousMutedCalls,
            callSid,
          ]);
        }
        // Remove the call SID from the muted calls list.
        else {
          setMutedCalls((previousMutedCalls) =>
            previousMutedCalls.filter(
              (mutedCallSid) => mutedCallSid !== callSid,
            ),
          );
        }
      };

      const onCallReject = () => {
        console.log("Call rejected.");
        removeCall({ call });
      };

      const onCallRing = (call: Call) => {
        console.log("Call ringing.");
      };

      call.on(TwilioCallEvent.ACCEPT, onCallAccept);
      call.on(TwilioCallEvent.CANCEL, onCallCancel);
      call.on(TwilioCallEvent.DISCONNECT, onCallDisconnect);
      call.on(TwilioCallEvent.MUTE, onCallMute);
      call.on(TwilioCallEvent.REJECT, onCallReject);
      call.on(TwilioCallEvent.RINGING, onCallRing);
    },
    [],
  );

  /**
   * Add the call to the list of connected calls.
   */
  const addCall = useCallback(
    ({
      call,
    }: {
      /**
       * Twilio call instance.
       */
      call: Call;
    }) => {
      const customCall = call as CallWithCustomProperties;

      // Append the date when the call was initiated to the call object.
      customCall.date = new Date();

      // Track if the call is muted.
      customCall.muted = call.isMuted();

      setCallEventListeners({ call: customCall });

      setCalls((previousCalls) => [...previousCalls, customCall]);

      /**
       * Accepts incoming call from the Twilio server.
       *
       * For incoming calls:
       * - This is done because incoming calls are queued in the server and is
       * later connected to the user.
       *
       * For outgoing calls:
       * - The backend service initiates a call to the client and then connects
       * it to the user.
       */
      call.accept();
    },
    [setCallEventListeners],
  );

  /**
   * Get the Twilio call associated with the provided call SID.
   *
   * @returns The Twilio call instance or null.
   */
  const getCall = useCallback<TwilioContext["getCall"]>(
    ({ callSid }) => {
      if (!calls?.length) {
        return null;
      }

      /**
       * Twilio call associated with this communication log card.
       */
      return (
        calls.find((twilioCall) => {
          /**
           * Initiating Twilio call SID.
           * This is defined in the connect to agent service on the server.
           */
          const twilioCallSid =
            // twilioCall.customParameters as unknown as CallCustomParameters;
            twilioCall.customParameters.get(
              "callSid",
            ) as CallCustomParameters["callSid"];

          return twilioCallSid === callSid;
        }) ?? null
      );
    },
    [calls],
  );

  /**
   * Checks if the call is muted.
   */
  const isMuted = useCallback<TwilioContext["isMuted"]>(
    ({ callSid }) => {
      return mutedCalls.includes(callSid);
    },
    [mutedCalls],
  );

  /**
   * Mutes a selected call.
   */
  const toggleMute = useCallback<TwilioContext["toggleMute"]>(
    ({ callSid }) => {
      /**
       * Twilio call instance.
       */
      const twilioCall = getCall({ callSid });

      if (!twilioCall) {
        return;
      }

      const newMuteStatus = !twilioCall.isMuted();

      twilioCall.mute(newMuteStatus);
    },
    [getCall],
  );

  // ===========================================================================
  // ===========================================================================
  // Effects
  // ===========================================================================
  // ===========================================================================

  /**
   * Initialize Twilio device if it hasn't been intiialized yet.
   */
  useEffect(() => {
    /**
     * Only authenticated users are allowed to use Twilio device.
     *
     * Make sure that only one device is initialized.
     */
    if (!user || device || deviceInitializing) {
      return;
    }

    initializeDevice();

    return () => {
      destroyDevice();
    };
  }, [destroyDevice, device, deviceInitializing, initializeDevice, user]);

  /**
   * Register or unregister Twilio device based on user availability status.
   */
  useEffect(() => {
    // Prevent any succeeding code from executing if device is not yet initialized.
    if (!device || deviceInitializing || deviceRegistering) {
      return;
    }

    // Register device if user is available and device is not registered.
    if (!deviceRegistered) {
      registerDevice();
    }
  }, [
    device,
    deviceInitializing,
    deviceRegistered,
    registerDevice,
    deviceRegistering,
  ]);

  /**
   * Display a notification when there's a Twilio device error.
   */
  useEffect(() => {
    // Set the user to offline if there's any Twilio device error.
    if (deviceError) {
      notification.error({
        message: "Twilio device error.",
        description: "An error occurred while updating Twilio device.",
        showProgress: true,
        pauseOnHover: true,
        key: "twilio-device-error",
      });
    }
  }, [deviceError]);

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  return (
    <TwilioContext.Provider
      value={{
        calls,
        // ===========================================================================
        // Twilio device states.
        // ===========================================================================
        device,
        deviceError,
        deviceInitializing,
        deviceRegistered,
        deviceRegistering,
        registerDevice,
        unregisterDevice,
        // ===========================================================================
        // Twilio call functions and states.
        // ===========================================================================
        isMuted,
        getCall,
        toggleMute,
      }}
    >
      {children}
    </TwilioContext.Provider>
  );
};
