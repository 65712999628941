"use client";

/**
 * Third-party library.
 */
import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

/**
 * Project components.
 */
import { Auth0User } from "@/components/common/auth0/types";
import { ApiRoute } from "@/components/common/route";
import { useIdle } from "@/components/client/hooks/use-idle";

/** The AuthContext value. */
type AuthenticationContextValue = {
  /** The authenticated user object. */
  user: Auth0User | null;
  /**
   * A function to check if still authenticated. This is best-practice to check
   * if still authenticated. Under the hood, it checks the `expires_at` value
   * of the token.
   */
  isAuthenticated: () => boolean;
  /** True when loading both 'initially' or when 'refetching'. Inspired by react-query. */
  fetching: boolean;
  /**
   * True when loading ONLY 'initially'. Never becomes `true` again unless
   * this component remounts again (full-page reload). Inspired by react-query.
   *
   * ```ts
   * // Can be used for checking if refetching and show a special UI for it.
   * const isRefetching = fetching && !loading;
   * ```
   */
  loading: boolean;
  /** The error value if the authenticated user could not be fetched. */
  error?: Error;
  /** Call this to refetch the user. */
  refetch: () => void;
};

// I. Context
const AuthenticationContext = createContext<AuthenticationContextValue>({
  user: null,
  isAuthenticated: () => false,
  fetching: false,
  loading: false,
  error: undefined,
  refetch: () => {},
});

// II. Hook
export const useAuthenticationContext = () => useContext(AuthenticationContext);

// III. Provider Component
export const AuthenticationContextProvider: FC<PropsWithChildren> = (props) => {
  const [user, setUser] = useState<Auth0User | null>(null);
  const [error, setError] = useState<Error>();
  // we are fetching always initially.
  const [fetching, setFetching] = useState(true);

  // For `loading` - so that it never becomes true after the initial fetch.
  const [loading, setLoading] = useState(true);
  const [finishedInitialFetch, setFinishedInitialFetch] = useState(false);

  /**
   * This implementation is based on
   * https://community.auth0.com/t/check-if-user-is-authenticated-or-not-auth-js-sdk/38452/3
   */
  const isAuthenticated = useCallback(() => {
    if (!user) return false;

    /** now timestamp in ms. */
    const now = new Date().getTime();

    /** expires_at timestamp in ms. */
    const expiresAt = user.expires_at * 1000;

    return now < expiresAt;
  }, [user]);

  const fetchUser = useCallback(async () => {
    setFetching(true);

    if (!finishedInitialFetch) setLoading(true);

    try {
      const response = await fetch(ApiRoute.AUTHENTICATION_ME);

      if (!response.ok) {
        // throw error?
      }

      const result = (await response.json()) as { user: Auth0User | null };

      if (result.user) {
        /** Number of milliseconds before token expires. */
        const msBeforeExpire =
          result.user?.expires_at * 1000 - new Date().getTime();

        // Side effect: Try to call fetchUser again when token expires.
        // So user doesn't have to refresh the app to get logged out.
        if (msBeforeExpire > 0) setTimeout(fetchUser, msBeforeExpire);
      }

      setUser(result.user ?? null);
    } catch (e: unknown) {
      // Type assertion to `unknown` is necessary here.
      // Otherwise, error ts(1196): must be Catch clause variable type annotation
      // must be 'any' or 'unknown' if specified.ts(1196)
      const error = e as Error;

      setError(error);
    } finally {
      setFetching(false);

      setFinishedInitialFetch(true);

      setLoading(false);
    }
  }, [finishedInitialFetch]);

  /**
   * Function that just calls the /refresh endpoint. Does not check if about
   * to expire. We use the `useRefreshTokenWhenActive` hook for that.
   */
  const refreshUser = useCallback(async () => {
    try {
      const response = await fetch(ApiRoute.AUTHENTICATION_REFRESH);

      if (!response.ok) {
        // throw error?
      }

      const result = (await response.json()) as {
        user: Auth0User | null;
        refreshed: boolean;
      };

      if (result.user) {
        /** Number of milliseconds before token expires. */
        const msBeforeExpire =
          result.user?.expires_at * 1000 - new Date().getTime();

        // Side effect: Try to call fetchUser again when token expires.
        // So user doesn't have to refresh the app to get logged out.
        if (msBeforeExpire > 0) setTimeout(fetchUser, msBeforeExpire);

        setUser(result.user);
      }

      // Don't set the user to null if refresh failed.
    } catch (e: unknown) {
      // Type assertion to `unknown` is necessary here.
      // Otherwise, error ts(1196): must be Catch clause variable type annotation
      // must be 'any' or 'unknown' if specified.ts(1196)
      const error = e as Error;

      setError(error);
    } finally {
      setFetching(false);

      setLoading(false);
    }
  }, [fetchUser]);

  // Fetch the user on load.
  useEffect(() => {
    // We only want to fetchUser the first time.
    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useRefreshTokenWhenActive({
    user: user ? { tokenExpiresAt: user.expires_at } : undefined,
    onRefreshToken: async () => {
      console.info("[useRefreshTokenWhenActive] 🚀 Refreshing token...");
      await refreshUser();
      console.info("[useRefreshTokenWhenActive] ✨ Refreshed token!");
    },
  });

  const value = useMemo(
    () => ({
      user: user,
      isAuthenticated: isAuthenticated,
      fetching: fetching,
      loading: loading,
      error: error,
      refetch: () => {
        fetchUser();
      },
    }),
    [error, fetchUser, fetching, isAuthenticated, loading, user],
  );

  return (
    <AuthenticationContext.Provider value={value}>
      {props.children}
    </AuthenticationContext.Provider>
  );
};

// ===========================================================================
// Internal Hooks for auth context
// ===========================================================================

/**
 * @constant
 * Milliseconds relative to expiry date. When current time reaches this point,
 * we will start refreshing the token.
 *
 * Currently 5 hours is just arbitrary and hard-coded. That means if user is still
 * active when his/her token expires in 5 hours, we will try refreshing the token.
 *
 * This must always be a constant (not a fraction or percentage).
 * This must always be less than the token duration (Current duration is 24 hours).
 *
 * NOTE If this value is more than the token duration, it's "possible" to create an infinite loop when user is active.
 */
const MS_BEFORE_EXPIRE_TO_START_REFRESH = 1000 * 60 * 60 * 5; // 5 hours

/**
 * Tries to refresh the token when the user is active.
 *
 * The premise is, active users get refreshed.
 * Inactive users, won't get refrehed.
 *
 * What is an "active user"?
 * Someone who emits any 'click', 'keypress', 'mousemove', 'touchmove', 'scroll' events.
 */
const useRefreshTokenWhenActive = (options: {
  user?: {
    /** Timestamp of when the token expires (in s). To convert to date, new Date(expires_at * 1000). */
    tokenExpiresAt: number;
  };
  onRefreshToken: () => Promise<void>;
}) => {
  const { user, onRefreshToken } = options;

  /**
   * Contains an internal timeout id when `timer.current` is accessed.
   * We use `useRef` so it's not reactive for performance.
   */
  const timerId = useRef<number>();

  /** Be "inactive" after X seconds. */
  const idle = useIdle(1000 * 60 * 30); // 30 minutes

  /** Memoized "token expires at timestamp in ms". */
  const tokenExpiresAt = useMemo(() => {
    return user?.tokenExpiresAt ?? null;
  }, [user?.tokenExpiresAt]);

  /**
   * This useEffect will only run if `idle` has changed to `true` -> `false` (vise-versa).
   *
   * It never gets called when value is set from `true` to `true`. (Which is
   * is a good use of useEffect because we can run a side-effect only when change happens).
   */
  useEffect(() => {
    // When inactive (Don't queue a refresh, let him expire)
    if (idle) {
      clearTimeout(timerId.current);
      timerId.current = undefined; // clear
      console.info("[useRefreshTokenWhenActive] CANCELLED.");
    }
    // When active (Queue a "refresh" before it expires)
    else {
      console.info("[useRefreshTokenWhenActive] START");
      /** 1. Make sure user exists. */
      if (tokenExpiresAt === null) return;

      /** 2. Milliseconds before token expires (from now). */
      const msBeforeExpire = tokenExpiresAt * 1000 - new Date().getTime();

      /** 3. Make sure not expired. */
      if (msBeforeExpire <= 0) return; // Don't try refresh when expired

      /** 4. Milliseconds before execute refreshing (from now)  */
      let refreshDelay = msBeforeExpire - MS_BEFORE_EXPIRE_TO_START_REFRESH;

      /** 5. If negative, it means we already passed "5 hours before token expires", so ideally try refreshing immediately. */
      if (refreshDelay < 0) refreshDelay = 0;

      console.info(
        `[useRefreshTokenWhenActive] Will refresh token in ${refreshDelay}ms or ${refreshDelay / 1000}s or ${refreshDelay / 1000 / 60 / 60}h`,
      );

      /**
       * 6. Execute Refreshing (now or in `refreshDelay` milliseconds).
       * Also only start timeout if it's none is running.
       */
      if (timerId.current === undefined) {
        timerId.current = window.setTimeout(async () => {
          console.info("[useRefreshTokenWhenActive] REFRESHING TOKEN");

          if (onRefreshToken) await onRefreshToken();

          timerId.current = undefined; // clear
        }, refreshDelay);
      }
    }
  }, [idle, onRefreshToken, tokenExpiresAt]);
};
