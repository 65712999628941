"use client";

/**
 * Third-party libraries.
 */
import { notification as antdNotification } from "antd";
import React, { PropsWithChildren } from "react";

import { ASSET_ROUTE } from "@/components/common/route";
import { NotificationInstance } from "antd/es/notification/interface";

/**
 * Application context.
 */

export type NotificationContext = {
  /**
   * Ant Design notification instance.
   *
   * Use this to show notifications.
   */
  notification: NotificationInstance & {
    /**
     * Create a desktop notification.
     */
    desktop: (args: {
      /**
       * Message of the notification.
       *
       * Shows on the body of the notification.
       */
      message?: string;
      /**
       * Tag of the notification.
       *
       * This will be used to close the notification.
       */
      tag: string;
      /**
       * Title of the notification.
       *
       * Shows on the notification header.
       */
      title: string;
    }) => void;
    /**
     * Close a desktop notification.
     */
    close: (args: {
      /**
       * Tag of the notification to close.
       */
      tag: string;
    }) => void;
  };
};

/**
 * Application context.
 */
const NotificationContext = React.createContext<NotificationContext>({
  notification: {
    close: () => {},
    desktop: () => {},
    destroy: () => {},
    error: () => {},
    info: () => {},
    open: () => {},
    success: () => {},
    warning: () => {},
  },
});

/**
 * Use Application Context hook.
 */
export const useNotificationContext = () => {
  return React.useContext(NotificationContext);
};

/**
 * Application context provider.
 */
export const NotificationContextProvider = ({
  children,
}: PropsWithChildren) => {
  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================
  /**
   * Ant Design notification.
   */
  const [notification, contextHolder] = antdNotification.useNotification({
    top: 64,
    stack: {
      threshold: 5,
    },
  });

  // ===========================================================================
  // ===========================================================================
  // Functions
  // ===========================================================================
  // ===========================================================================

  const desktop: NotificationContext["notification"]["desktop"] = ({
    message = "",
    title,
  }) => {
    if (!("Notification" in window)) {
      // Check if the browser supports notifications
      console.warn("This browser does not support desktop notification");
      return;
    }

    function showNotification({
      title,
      message,
    }: {
      /**
       * Message of the notification.
       *
       * Shows on the body of the notification.
       */
      message: string;
      /**
       * Title of the notification.
       *
       * Shows on the notification header.
       */
      title: string;
    }) {
      if (!navigator.serviceWorker) {
        console.warn("Service Worker is not supported");
        return;
      }

      navigator.serviceWorker.ready.then((registration) => {
        registration.showNotification(title, {
          body: message,
          badge: ASSET_ROUTE.IMAGE.CLOUD_CONTACT_CENTER_LOGO,
          icon: ASSET_ROUTE.IMAGE.CLOUD_CONTACT_CENTER_LOGO,
          tag: "incoming-call",
        });
      });
    }

    switch (Notification.permission) {
      // If it's okay let's create a notification
      case "granted":
        showNotification({
          title,
          message,
        });
        break;
      // If the user has denied the notification permission
      case "denied":
        console.warn("You have blocked notifications");
        break;
      // If the user has not been asked to grant or deny notifications
      case "default":
        // We need to ask the user for permission
        Notification.requestPermission().then((permission) => {
          // If the user accepts, let's create a notification
          if (permission === "granted") {
            showNotification({
              title,
              message,
            });
          } else {
            console.warn("You have not allowed notifications");
          }
        });
        break;
    }
  };

  const close = () => {
    if (!navigator.serviceWorker) {
      console.warn("Service Worker is not supported");
      return;
    }

    navigator.serviceWorker.ready.then((registration) => {
      registration
        .getNotifications({ tag: "incoming-call" })
        .then((notifications) => {
          notifications.forEach((notification) => {
            notification.close(); // Close the notification
          });
        });
    });
  };

  // ===========================================================================
  // ===========================================================================
  // Effects
  // ===========================================================================
  // ===========================================================================

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  return (
    <NotificationContext.Provider
      value={{
        notification: {
          ...notification,
          close,
          desktop,
        },
      }}
    >
      {contextHolder}
      {children}
    </NotificationContext.Provider>
  );
};
