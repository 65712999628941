"use client";

/**
 * Third-party libraries.
 */
import AntdIcon from "@ant-design/icons";
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";
import {
  ComponentType,
  ForwardRefExoticComponent,
  SVGProps,
  useEffect,
  useState,
} from "react";

import "./icon.css";

type IconSVG =
  | "caret-down"
  | "chart"
  | "copy"
  | "dial-pad"
  | "filter"
  | "flag"
  | "gear"
  | "inbox-empty"
  | "inbox-empty-with-shadow"
  | "microphone"
  | "microphone-mute"
  | "more-horizontal"
  | "person"
  | "pause"
  | "phone"
  | "phone-down"
  | "phone-incoming"
  | "play-arrow"
  | "refresh"
  | "search"
  | "transfer"
  | "the-pique-lab-logo"
  | "volume-high"
  | "volume-low"
  | "volume-mute";

/**
 * Properties for the Icon component.
 */
export type IconProps = {
  /**
   * The name (without the extension) of the SVG file to load.
   */
  src: IconSVG;
  /**
   * Additional class name for the icon container.
   *
   * You would have to mark the following CSS attributes as important to override:
   * - font-size
   * - color
   */
  className?: string;
};

/**
 * Displays an icon using an SVG from the "images" folder.
 */
export const Icon = ({ src, className }: IconProps) => {
  const [SvgIcon, setSvgIcon] = useState<
    | ComponentType<CustomIconComponentProps | SVGProps<SVGSVGElement>>
    | ForwardRefExoticComponent<CustomIconComponentProps>
    | undefined
  >(undefined);

  useEffect(() => {
    const fetchSvg = async () => {
      try {
        const importedSvg = await import(
          `@/components/client/images/${src}.svg`
        );

        setSvgIcon(() => importedSvg.default);
      } catch (error) {
        console.error(`Error loading SVG: ${src}`, error);
      }
    };

    fetchSvg();
  }, [src]);

  if (!SvgIcon) {
    return null;
  }

  return <AntdIcon className={className} component={SvgIcon} />;
};
